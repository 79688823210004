import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RESERVATION_STATUS } from '@app/constants/reservation_status.constants';
import { ROLES } from '@app/constants/roles.constants';
import { DialogService } from '@app/services/common/dialog.service';
import { UserRoleService } from '@app/services/common/user-role.service';
import { EquipmentService } from '@app/services/data/equipment.service';
import {
  formatDate,
  MbscCalendarEvent,
  MbscEventcalendarOptions,
  MbscEventcalendarView,
  MbscPopup,
  MbscPopupOptions,
  Notifications,
  setOptions,
} from '@mobiscroll/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Reservation, ReservationMobiscroll } from 'src/app/data';
import { SdtBaseComponent } from 'src/app/features/SdtBaseComponent';

@Component({
  selector: 'app-reservation-timeline',
  templateUrl: './reservation-timeline.component.html',
  styleUrls: ['./reservation-timeline.component.scss'],
  providers: [Notifications],
})
export class ReservationTimelineComponent
  extends SdtBaseComponent
  implements OnInit, OnChanges
{
  @Input() isShowAllHours!: boolean;
  @Input() isSearchUniqueCode!: boolean;
  @Input() reservations!: Reservation[] | null;
  @Input() selectedDate!: string | null;
  @Input() printCalendar!: number;
  @Output() selectedWeek: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatedDate: EventEmitter<string> = new EventEmitter<string>();
  @Output() getReservations: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('eventCalendar', { static: false })
  eventCalendar!: MbscCalendarEvent;

  @ViewChild('popup', { static: false })
  tooltip!: MbscPopup;

  calendarModules = [print];
  reservationsMobiscroll!: ReservationMobiscroll[];

  currentEvent: any;
  status = '';
  buttonText = '';
  buttonType = '';
  bgColor = '';
  info = '';
  time = '';
  reason = '';
  location = '';
  anchor: HTMLElement | undefined;
  timer: any;
  reservationStatus = RESERVATION_STATUS;

  myEvents: MbscCalendarEvent[] = [];
  timelineView: MbscEventcalendarView = {
    timeline: { type: 'week' },
  };
  canEditReservation: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reservations && changes.reservations.currentValue) {
      this.convertReservationsMobiscroll();
      this.showAllreservations();

      if (
        this.isSearchUniqueCode &&
        this.reservations &&
        this.reservations.length === 1
      ) {
        this.eventCalendar.navigate(this.reservations[0].startDate);
      }
    }

    if (changes.selectedDate && !changes.selectedDate.firstChange) {
      this.eventCalendar.navigate(changes.selectedDate.currentValue);
    }

    if (changes.printCalendar && !changes.printCalendar.firstChange) {
      this.eventCalendar.print();
    }

    if (changes.isShowAllHours && !changes.isShowAllHours.firstChange) {
      this.toggleSHowALlHours();
    }
  }

  isFileImage(fileName: string) {
    return fileName?.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/i);
  }

  toggleSHowALlHours() {
    setTimeout(() => {
      if (this.isShowAllHours) {
        // this.calendarOptions!.responsive!.xsmall.view.timeline.startTime =
        //   '02:00';
        // this.calendarOptions!.responsive!.xsmall.view.timeline.endTime = '23:00';

        // this.calendarOptions!.view!.timeline!.startTime = '02:00';
        // this.calendarOptions!.view!.timeline!.endTime = '23:00';

        this.timelineView.timeline!.type = 'week';
        this.timelineView.timeline!.startTime = '02:00';
        this.timelineView.timeline!.endTime = '23:00';
      } else {
        // this.calendarOptions!.responsive!.xsmall.view.timeline.startTime =
        //   '06:00';
        // this.calendarOptions!.responsive!.xsmall.view.timeline.endTime = '19:00';

        // this.calendarOptions!.view!.timeline!.startTime = '06:00';
        // this.calendarOptions!.view!.timeline!.endTime = '19:00';
        this.timelineView.timeline!.type = 'week';
        this.timelineView.timeline!.startTime = '06:00';
        this.timelineView.timeline!.endTime = '19:00';
      }
    }, 200);

    // debugger;

    setOptions({ view: this.timelineView });

    setOptions({
      view: {
        timeline: { type: 'week', startTime: '08:00', endTime: '14:00' },
      },
    });
    // setOptions(this.calendarOptions);
  }

  convertReservationsMobiscroll() {
    this.reservationsMobiscroll = this.reservations!.map(
      (reservation: Reservation) => {
        return this.mobiscrollObjMapping(reservation);
      }
    );
  }

  mobiscrollObjMapping(reservation: Reservation) {
    return {
      id: reservation.id!,
      uniqueCode: reservation.uniqueCode,
      resource: reservation.equipmentId,
      equipmentId: reservation.equipmentId,
      approvalStatus: reservation.approvalStatus,
      start: moment(reservation.startDate!).format('YYYY-MM-DD[T]HH:mm'),
      end: moment(reservation.endDate!).format('YYYY-MM-DD[T]HH:mm'),
      recurring: reservation.rRule,
      title: reservation.carrier,
      isHazard: reservation.hasHazardousMaterial,
      carrier: reservation.carrier,
      carrierEmails: reservation.carrierEmails,
      carrierNo: reservation.carrierNo,
      typeOfTrailer: reservation.typeOfTrailer,
      palletsInfo: reservation.palletsInfo,
      billOfLanding: reservation.billOfLanding,
      hasHazardousMaterial: reservation.hasHazardousMaterial,
      supplier: reservation.supplier,
      purcharseOrders: reservation.purcharseOrders,
      equipementCrewId: reservation.equipementCrewId,
      equipmentName: reservation.equipmentName,
      userId: reservation.userId,
      userName: reservation.userName,
      description: reservation.description,
      deviceId: reservation.equipmentDeviceId,
      documents: reservation.documents,
      qrcodeUrl: reservation.qrcodeUrl,
      color: reservation.status
        ? this.reservationStatus[reservation.status].color
        : this.reservationStatus[5].color,
      status: reservation.status,
      text: `<div style="text-align: center; margin-bottom: 10px;"></div><div class="my-block"><i class="fa-solid fa-truck mr-1"></i>${
        reservation.equipmentName
      }</div><div class="my-desc"><i class="fa-solid fa-user mr-1"></i>${
        reservation.userName
      }</div><div><i class="fa-solid fa-comment mr-1"></i>${
        reservation.description || 'N/A'
      }</div>`,
    };
  }

  editAppointment(id: number) {
    this.router.navigate(['reservation/modify', id]);
  }

  showAllreservations() {
    this.myEvents = [...(this.reservationsMobiscroll || [])];
  }

  calendarOptions: MbscEventcalendarOptions = {
    responsive: {
      xsmall: {
        view: {
          timeline: {
            type: 'week',
            // startTime: '06:00',
            // endTime: '19:00',
          },
        },
      },
    },
    view: {
      timeline: {
        type: 'week',
        // startTime: '06:00',
        // endTime: '19:00',
      },
    },
    dragToResize: false,
    dragToMove: false,
    clickToCreate: false,
    resources: [
      {
        id: 1,
        name: 'Dock 1',
        color: '#000',
      },
      {
        id: 2,
        name: 'Dock 2',
        color: '#000',
      },
      {
        id: 3,
        name: 'Dock 3',
        color: '#000',
      },
      {
        id: 4,
        name: 'Dock 4',
        color: '#000',
      },
      {
        id: 5,
        name: 'Dock 5',
        color: '#000',
      },
      {
        id: 6,
        name: 'Dock 6',
        color: '#000',
      },
      {
        id: 7,
        name: 'Dock 7',
        color: '#000',
      },
      {
        id: 8,
        name: 'Dock 8',
        color: '#000',
      },
      {
        id: 9,
        name: 'Dock 9',
        color: '#000',
      },
      {
        id: 10,
        name: 'Dock 10',
        color: '#000',
      },
      {
        id: 11,
        name: 'Dock 11',
        color: '#000',
      },
    ],
    colors: [
      {
        background: '#a5ceff4d',
        slot: 1,
        recurring: {
          repeat: 'weekly',
          weekDays: 'MO,TU,WE,TH,FR',
        },
      },
      {
        background: '#f7f7bb4d',
        slot: 2,
        recurring: {
          repeat: 'weekly',
          weekDays: 'MO,TU,WE,TH,FR',
        },
      },
    ],
    onPageChange: (event, inst) => {
      const weekDate = {
        startDate: moment(new Date(event.firstDay)).format('YYYY-MM-DD'),
        endDate: moment(new Date(event.lastDay)).format('YYYY-MM-DD'),
      };

      this.selectedWeek.emit(weekDate);
    },
    onSelectedDateChange: (event, inst) => {
      this.updatedDate.emit(moment(event.date).format('YYYY-MM-DD'));
    },
    onEventHoverIn: (args, inst) => {
      const event: any = args.event;

      const resource: any = this.myResources.find(
        (dr) => dr.id === event.resource
      );
      const time =
        formatDate('hh:mm A', new Date(event.start)) +
        ' - ' +
        formatDate('hh:mm A', new Date(event.end));

      this.canEdit(event.equipementCrewId, event.userId, event.start);

      this.currentEvent = event;
      this.bgColor = event.status
        ? this.reservationStatus[event.status].color
        : this.reservationStatus[5].color;
      this.info = event.title;
      this.time = time;
      this.reason = event.reason;
      this.location = event.location;

      clearTimeout(this.timer);
      this.timer = null;

      this.anchor = args.domEvent.target;
      this.tooltip.open();
    },
    onEventHoverOut: () => {
      if (!this.timer) {
        this.timer = setTimeout(() => {
          this.tooltip.close();
        }, 200);
      }
    },
    onEventClick: () => {
      this.tooltip.open();
    },
  };

  popupOptions: MbscPopupOptions = {
    display: 'anchored',
    touchUi: false,
    showOverlay: false,
    contentPadding: false,
    closeOnOverlayClick: false,
    width: 350,
    height: 460,
  };

  mouseEnter(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  mouseLeave(): void {
    this.timer = setTimeout(() => {
      this.tooltip.close();
    }, 200);
  }

  myResources = [
    {
      id: 1,
      name: 'Dock 1',
      color: '#000',
    },
    {
      id: 2,
      name: 'Dock 2',
      color: '#000',
    },
    {
      id: 3,
      name: 'Dock 3',
      color: '#000',
    },
    {
      id: 4,
      name: 'Dock 4',
      color: '#000',
    },
    {
      id: 5,
      name: 'Dock 5',
      color: '#000',
    },
    {
      id: 6,
      name: 'Dock 6',
      color: '#000',
    },
    {
      id: 7,
      name: 'Dock 7',
      color: '#000',
    },
    {
      id: 8,
      name: 'Dock 8',
      color: '#000',
    },
    {
      id: 9,
      name: 'Dock 9',
      color: '#000',
    },
    {
      id: 10,
      name: 'Dock 10',
      color: '#000',
    },
    {
      id: 11,
      name: 'Dock 11',
      color: '#000',
    },
  ];

  constructor(
    private notify: Notifications,
    private router: Router,
    private route: ActivatedRoute,
    private roleUserService: UserRoleService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private equipmentService: EquipmentService,
    private toastrService: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {}

  confirmRemoveReservation(equiId: number, id: number) {
    this.dialogService
      .confirm(
        this.translate.instant(
          'features.reservation.CONFIRM_DELETE_RESERVATION.TITLE'
        ),
        this.translate.instant(
          'features.reservation.CONFIRM_DELETE_RESERVATION.DESCRIPTION'
        )
      )
      .subscribe((result: any) => {
        if (result) {
          this.equipmentService
            .deleteReservation(this.site.code, equiId, id)
            .subscribe(() => {
              this.getReservations.emit();

              this.toastrService.success(
                this.translate.instant(
                  'features.reservation.DELETE_RESERVATION_SUCCESS'
                )
              );
            });
        }
      });
  }

  isDocumentImage(name: string) {
    const imagesFormat = ['jpg', 'png', 'jpeg'];

    return imagesFormat.some((i) => name.includes(i));
  }

  canEdit(id: number, userId: number, startDate: string): boolean {
    const localStorageSite = localStorage.getItem('SDT_SITE');

    if (localStorageSite) {
      const site = JSON.parse(localStorageSite);
      const roles = this.roleUserService.getRoles() || [];
      const siteRole = ROLES.SITE_MANAGER.replace('{siteId}', site.id);
      const requiredRoles = [
        siteRole,
        ROLES.ADMIN,
        `Crew.${id}.Manager.ReadWrite.All`,
      ];

      if (requiredRoles.some((r) => roles.includes(r))) {
        this.canEditReservation = true;
      } else {
        this.canEditReservation = false;
      }

      if (userId === this.profile.id) {
        this.canEditReservation = true;
      }

      if (moment().isAfter(moment(startDate))) {
        this.canEditReservation = false;
      }
    }

    return this.canEditReservation;
  }

  getStatusColor(status: number) {
    let styleStatusColor = 'background: ';

    styleStatusColor += status
      ? this.reservationStatus[status].color
      : this.reservationStatus[5].color;

    return styleStatusColor;
  }
}
